<template>
  <div class="card mt-3">
    <div class="card-body">
      <h4 class="card-title">HOME PAGE VIDEO</h4>
      <form>
        <div>
          <video
            v-if="imageUrl !== '/placeholder.png'"
            :src="imageUrl"
            autoplay="true"
            muted="true"
            loop
            class="img-fluid w-25 d-inline-block"
          />
          <img
            v-if="imageUrl === '/placeholder.png'"
            :src="imageUrl"
            class="img-fluid w-25 d-inline-block"
          />
        </div>
        <div class="form-group d-inline-block">
          <label for="exampleFormControlFile1"></label>
          <input
            @change="setImagePreview"
            ref="imageInput"
            type="file"
            class="form-control-file"
            id="exampleFormControlFile1"
          />
        </div>

        <button @click.prevent="saveBanner" class="btn btn-block btn-primary">
          Submit
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      imageUrl: "/placeholder.png",
      bannerField: "homeBanner",
    };
  },
  methods: {
    async setImagePreview() {
      const fr = new FileReader();
      fr.onload = () => {
        this.imageUrl = fr.result;
      };

      const src = fr.readAsDataURL(this.$refs.imageInput.files[0]);
    },
    async saveBanner() {
      try {
        const filename = await this.$store.dispatch(
          "uploadImage",
          this.$refs.imageInput.files[0]
        );

        const res = await axios.patch(
          "/api/v1/settings/banners/" + this.bannerField,
          { bannerImage: filename },
          {
            headers: {
              authorization: `bearer ${this.$store.state.token}`,
            },
          }
        );

        if (res.status == 201) {
          this.$router.go("/banners");
        }
      } catch (e) {
        // HANDLE ERROR
        console.log(e);
      }
    },
    async getBanner() {
      try {
        const res = await axios.get("/api/v1/settings");

        if (res.data.settings.banners[this.bannerField]) {
          this.imageUrl = `/uploads/${
            res.data.settings.banners[this.bannerField]
          }`;
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  mounted() {
    this.getBanner();
  },
};
</script>

<style>
</style>