<template>
  <div class="card mt-3">
    <div class="card-body">
      <h4 class="card-title">CAREERS BANNER</h4>
      <form>
        <div class="d-flex">
          <!-- Page Banner -->
          <div class="w-25">
            <div>
              <img
                :src="imageUrl"
                class="d-inline-block"
                style="width: 300px"
              />
            </div>
            <div class="form-group d-inline-block">
              <label for="exampleFormControlFile1">Desktop Banner</label>
              <input
                @change="setImagePreview"
                ref="imageInput"
                type="file"
                class="form-control-file"
                id="exampleFormControlFile1"
              />
            </div>
          </div>
          <div class="w-25">
            <div>
              <img
                :src="imageUrlMobile"
                class="d-inline-block"
                style="width: 300px"
              />
            </div>
            <div class="form-group d-inline-block">
              <label for="exampleFormControlFile1">Mobile Banner</label>
              <input
                @change="setImagePreviewMobile"
                ref="imageInputMobile"
                type="file"
                class="form-control-file"
                id="exampleFormControlFile1"
              />
            </div>
          </div>
          <!-- / Page Banner -->
        </div>
        <div class="d-flex mt-3" style="gap: 10px">
          <!-- GRID IMG 1 -->
          <div class="">
            <div>
              <img
                :src="_gridImages['1']"
                class="d-inline-block"
                style="width: 300px"
              />
            </div>
            <div class="form-group d-inline-block">
              <label for="exampleFormControlFile2">GRID IMAGE 1</label>
              <input
                @change="setImagePreview"
                ref="grid1Input"
                type="file"
                class="form-control-file"
                id="exampleFormControlFile2"
              />
            </div>
          </div>
          <!-- / GRID IMG 1 -->
          <!-- GRID IMG 2 -->
          <div class="">
            <div>
              <img
                :src="_gridImages['2']"
                class="d-inline-block"
                style="width: 300px"
              />
            </div>
            <div class="form-group d-inline-block">
              <label for="exampleFormControlFile3">GRID IMAGE 2</label>
              <input
                @change="setImagePreview"
                ref="grid2Input"
                type="file"
                class="form-control-file"
                id="exampleFormControlFile3"
              />
            </div>
          </div>
          <!-- / GRID IMG 2 -->
          <!-- GRID IMG 3 -->
          <div class="">
            <div>
              <img
                :src="_gridImages['3']"
                class="d-inline-block"
                style="width: 300px"
              />
            </div>
            <div class="form-group d-inline-block">
              <label for="exampleFormControlFile4">GRID IMAGE 3</label>
              <input
                @change="setImagePreview"
                ref="grid3Input"
                type="file"
                class="form-control-file"
                id="exampleFormControlFile4"
              />
            </div>
          </div>
          <!-- / GRID IMG 3 -->
          <!-- GRID IMG 4 -->
          <div class="">
            <div>
              <img
                :src="_gridImages['4']"
                class="d-inline-block"
                style="width: 300px"
              />
            </div>
            <div class="form-group d-inline-block">
              <label for="exampleFormControlFile5">GRID IMAGE 4</label>
              <input
                @change="setImagePreview"
                ref="grid4Input"
                type="file"
                class="form-control-file"
                id="exampleFormControlFile5"
              />
            </div>
          </div>
          <!-- / GRID IMG 4 -->
        </div>

        <button
          @click.prevent="saveBanner"
          class="btn mt-3 btn-block btn-primary"
        >
          Submit
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      imageUrl: "/placeholder.png",
      imageUrlMobile: "/placeholder.png",
      gridImages: {
        1: "/placeholder.png",
        2: "/placeholder.png",
        3: "/placeholder.png",
        4: "/placeholder.png",
      },
      bannerField: "careersBanner",
    };
  },
  methods: {
    async setImagePreview() {
      if (this.$refs.imageInput.files[0]) {
        const fr = new FileReader();
        fr.onload = () => {
          this.imageUrl = fr.result;
        };

        const src = fr.readAsDataURL(this.$refs.imageInput.files[0]);
      }

      for (const field in this.gridImages) {
        if (this.$refs[`grid${field}Input`].files[0]) {
          const _fr = new FileReader();

          _fr.onload = () => (this.gridImages[field] = _fr.result);

          const _src = _fr.readAsDataURL(
            this.$refs[`grid${field}Input`].files[0]
          );
        }
      }
    },
    async setImagePreviewMobile() {
      const fr = new FileReader();
      fr.onload = () => {
        this.imageUrlMobile = fr.result;
      };

      const src = fr.readAsDataURL(this.$refs.imageInputMobile.files[0]);
    },
    async saveBanner() {
      try {
        let update = {
          bannerImage: this.imageUrl.replace("/uploads/", ""),
          gridImages: this.gridImages,
        };
        if (this.$refs.imageInput.files[0]) {
          const filename = await this.$store.dispatch(
            "uploadImage",
            this.$refs.imageInput.files[0]
          );
          update.bannerImage = filename;
        }

        if (this.$refs.imageInputMobile.files[0]) {
          const filename = await this.$store.dispatch(
            "uploadImage",
            this.$refs.imageInputMobile.files[0]
          );
          update.bannerImageMobile = filename;
          console.log(update.bannerImageMobile);
        }

        for (const field in this.gridImages) {
          if (this.$refs[`grid${field}Input`].files[0]) {
            const _filename = await this.$store.dispatch(
              "uploadImage",
              this.$refs[`grid${field}Input`].files[0]
            );
            if (!update.gridImages) {
              update.gridImages = {};
            }
            update.gridImages[field] = _filename;
          }
        }

        const res = await axios.patch(
          "/api/v1/settings/banners/" + this.bannerField + "?careers=true",
          // { bannerImage: filename },
          update,
          {
            headers: {
              authorization: `bearer ${this.$store.state.token}`,
            },
          }
        );

        if (res.status == 201) {
          this.$router.go("/banners");
        }
      } catch (e) {
        // HANDLE ERROR
        console.log(e);
      }
    },
    async getBanner() {
      try {
        const res = await axios.get("/api/v1/settings");

        if (
          res.data.settings.banners[this.bannerField] &&
          res.data.settings.banners[this.bannerField].bannerImage
        ) {
          this.imageUrl = `/uploads/${
            res.data.settings.banners[this.bannerField].bannerImage
          }`;
        }

        if (
          res.data.settings.banners[this.bannerField] &&
          res.data.settings.banners[this.bannerField].bannerImageMobile
        ) {
          this.imageUrlMobile = `/uploads/${
            res.data.settings.banners[this.bannerField].bannerImageMobile
          }`;
        }

        const _gridImages =
          res.data.settings.banners[this.bannerField].gridImages;
        console.log(_gridImages);
        if (_gridImages) {
          for (const _field in _gridImages) {
            this.gridImages[_field] = _gridImages[_field] || "/placeholder.png";
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  computed: {
    _gridImages() {
      const obj = { 1: "", 2: "", 3: "", 4: "" };

      for (const _f in this.gridImages) {
        if (this.gridImages[_f] === "/placeholder.png") {
          obj[_f] = this.gridImages[_f];
        } else if (this.gridImages[_f].match(/data\:image/i)) {
          obj[_f] = this.gridImages[_f];
        } else {
          obj[_f] = "/uploads/" + this.gridImages[_f];
        }
      }

      return obj;
    },
  },
  mounted() {
    this.getBanner();
  },
};
</script>

<style>
</style>