<template>
  <div class="card mt-3">
    <div class="card-body">
      <h4 class="card-title">PARTNER BRANDS BANNER</h4>
      <form>
        <div class="d-flex" style="gap: 10px">
          <div class="w-25">
            <div>
              <img :src="imageUrl" class="img-fluid d-inline-block" />
            </div>
            <div class="form-group d-inline-block">
              <label for="exampleFormControlFile1">Desktop</label>
              <input
                @change="setImagePreview"
                ref="imageInput"
                type="file"
                class="form-control-file"
                id="exampleFormControlFile1"
              />
            </div>
          </div>
          <div class="w-25">
            <div>
              <img :src="imageUrlMobile" class="img-fluid d-inline-block" />
            </div>
            <div class="form-group d-inline-block">
              <label for="exampleFormControlFile1">Mobile</label>
              <input
                @change="setImagePreviewMobile"
                ref="imageInputMobile"
                type="file"
                class="form-control-file"
                id="exampleFormControlFile1"
              />
            </div>
          </div>
        </div>

        <button @click.prevent="saveBanner" class="btn btn-block btn-primary">
          Submit
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      imageUrl: "/placeholder.png",
      bannerField: "partnerBrandsBanner",
      imageUrlMobile: "/placeholder.png",
      bannerFieldMobile: "partnerBrandsBannerMobile",
    };
  },
  methods: {
    async setImagePreview() {
      const fr = new FileReader();
      fr.onload = () => {
        this.imageUrl = fr.result;
      };

      const src = fr.readAsDataURL(this.$refs.imageInput.files[0]);
    },
    async setImagePreviewMobile() {
      const fr = new FileReader();
      fr.onload = () => {
        this.imageUrlMobile = fr.result;
      };

      const src = fr.readAsDataURL(this.$refs.imageInputMobile.files[0]);
    },
    async saveBanner() {
      try {
        if (this.$refs.imageInput.files[0]) {
          const filename = await this.$store.dispatch(
            "uploadImage",
            this.$refs.imageInput.files[0]
          );

          await axios.patch(
            "/api/v1/settings/banners/" + this.bannerField,
            { bannerImage: filename },
            {
              headers: {
                authorization: `bearer ${this.$store.state.token}`,
              },
            }
          );
        }

        if (this.$refs.imageInputMobile.files[0]) {
          const filename = await this.$store.dispatch(
            "uploadImage",
            this.$refs.imageInputMobile.files[0]
          );

          await axios.patch(
            "/api/v1/settings/banners/" + this.bannerFieldMobile,
            { bannerImage: filename },
            {
              headers: {
                authorization: `bearer ${this.$store.state.token}`,
              },
            }
          );
        }

        this.$router.go("/banners");
      } catch (e) {
        // HANDLE ERROR
        console.log(e);
      }
    },
    async getBanner() {
      try {
        const res = await axios.get("/api/v1/settings");

        if (res.data.settings.banners[this.bannerField]) {
          this.imageUrl = `/uploads/${
            res.data.settings.banners[this.bannerField]
          }`;
        }

        if (res.data.settings.banners[this.bannerFieldMobile]) {
          this.imageUrlMobile = `/uploads/${
            res.data.settings.banners[this.bannerFieldMobile]
          }`;
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  mounted() {
    this.getBanner();
  },
};
</script>

<style>
</style>