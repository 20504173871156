<template>
  <main-layout>
    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">BANNERS</h1>
    </div>

    <!-- <HomePageBanner /> -->
    <InnovationsBanner />
    <AboutUsBanner />
    <ServicesBanner />
    <HeadquartersBanner />
    <PartnerBrandsBanner />
    <PartnershipBanner />
    <EventsBanner />
    <ContactUsBanner />
    <CareersBanner />
  </main-layout>
</template>

<script>
import axios from "axios";
import MainLayout from "@/components/layout/MainLayout";
import AboutUsBanner from "@/components/BannerForms/AboutUsBanner";
import ServicesBanner from "@/components/BannerForms/ServicesBanner";
import HeadquartersBanner from "@/components/BannerForms/HeadquartersBanner";
import PartnerBrandsBanner from "@/components/BannerForms/PartnerBrandsBanner";
import PartnershipBanner from "@/components/BannerForms/PartnershipBanner";
import EventsBanner from "@/components/BannerForms/EventsBanner";
import ContactUsBanner from "@/components/BannerForms/ContactUsBanner";
import HomePageBanner from "@/components/BannerForms/HomePageBanner";
import CareersBanner from "@/components/BannerForms/CareersBanner";
import InnovationsBanner from "@/components/BannerForms/InnovationsBanner";

export default {
  name: "AddBrand",
  components: {
    MainLayout,
    AboutUsBanner,
    ServicesBanner,
    HeadquartersBanner,
    PartnerBrandsBanner,
    PartnershipBanner,
    EventsBanner,
    ContactUsBanner,
    HomePageBanner,
    CareersBanner,
    InnovationsBanner,
  },
};
</script>

<style>
</style>